<template>
  <v-simple-table fixed-header :height="view ? '100px' : '170px'">
    <template v-slot:default>
      <thead v-if="!view">
        <tr>
          <th class="text-left font-weight-regular">
            <form ref="form">
              <v-text-field
                v-model.trim="enlace"
                label="Link"
              ></v-text-field>
            </form>
          </th>
          <th class="text-right">
            <v-btn
              color="success"
              small
              @click="save"
            >Agregar</v-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in usuario.enlaces"
          :key="index"
        >
          <td><a :href="item" target="_blank">{{ item }}</a></td>
          <td v-if="!view">
            <div class="d-flex justify-end">
              <DeleteTableItem nombre="enlace" @action="delet(index)" />
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { db } from '../firebase'
import { mapActions, mapState } from 'vuex'
import Confirmar from '../components/utils/Confirmar'
import DeleteTableItem from '../components/utils/DeleteTableItem'

export default {
  data() {
    return {
      enlace: '',
    }
  },
  props: {
    usuario: Object,
    view: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Confirmar, DeleteTableItem
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions(['update_user']),
    async save() {
      if (this.enlace !== '') {
        let enlaces = [...this.usuario.enlaces]
        enlaces.push(this.enlace)
        await this.actualizar_db(enlaces)
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'No puede agregar un link vacío',
          color: 'warning'
        })
      }
    },

    async delet(index) {
      let enlaces = [...this.usuario.enlaces]
      enlaces.splice(index, 1)
      await this.actualizar_db(enlaces)
    },

    async actualizar_db (enlaces) {
      this.$store.state.loading_screen = true
      await db.collection('usuarios').doc(this.usuario.id).update({ enlaces: enlaces })
        .then(() => {
          this.usuario.enlaces = [...enlaces]
          if (this.user.rol != 'Admin') {
            this.update_user(this.usuario)
          }
          this.enlace = ''
          this.$store.dispatch('show_snackbar', {
            text: 'Los cambios se guardaron correctamente',
            color: 'success',
          })
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading_screen = false
    }

  }
}
</script>