<template>
  <v-simple-table fixed-header height="200px">
    <template v-slot:default>
      <thead>
        <tr>
          <th v-if="!$vuetify.breakpoint.xs" class="text-left"><h3>Periodo</h3></th>
          <th class="text-left"><h3>Nivel</h3></th>
          <th class="text-left"><h3>Título</h3></th>
          <th class="text-right">
            <v-dialog
              v-model="dialog"
              width="400"
              scrollable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  title="Agregar"
                  :disabled="view"
                  icon
                  v-bind="attrs"
                  v-on="on"
                ><v-icon>fas fa-plus</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-title v-if="!view" class="font-weight-light">{{ formTitle }} formación académica</v-card-title>
                <v-card-title v-else class="font-weight-light">Detalle</v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-select
                          v-model="formacion.nivel"
                          :rules="[rules.required]"
                          :items="tipos_formacion_academica"
                          label="Formación académica"
                          validate-on-blur
                          :readonly="view"
                          :filled="view"
                          @change="curso"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="formacion.institucion"
                          :rules="[rules.required]"
                          label="Institución"
                          validate-on-blur
                          :readonly="view"
                          :filled="view"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="formacion.titulo"
                          :rules="[rules.required]"
                          label="Título"
                          validate-on-blur
                          :readonly="view"
                          :filled="view"
                        ></v-text-field>
                      </v-col>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="formacion.inicio"
                            :rules="[rules.required]"
                            prepend-icon="fas fa-calendar"
                            v-mask="'####'"
                            type="number"
                            :max="new Date().getFullYear()"
                            label="Año de inicio"
                            validate-on-blur
                            :readonly="view"
                            :filled="view"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-if="encurso"
                            v-model="formacion.fin"
                            disabled
                            prepend-icon="fas fa-hourglass-half"
                            label="En curso"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model="formacion.fin"
                            :rules="[rules.required]"
                            prepend-icon="fas fa-calendar"
                            v-mask="'####'"
                            type="number"
                            :max="new Date().getFullYear()"
                            label="Año de finalización"
                            validate-on-blur
                            :readonly="view"
                            :filled="view"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model.trim="formacion.observaciones"
                          label="Observaciones"
                          auto-grow
                          rows="2"
                          :readonly="view"
                          :filled="view"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-alert
                    v-if="alert.value"
                    border="left"
                    text
                    dense
                    :type="alert.type"
                  >
                    {{ alert.message }}
                  </v-alert>
                </v-card-text>
                <v-card-actions class="d-flex justify-end pb-4">
                  <div v-if="!view">
                    <v-btn
                      color="error"
                      class="mr-4"
                      @click="dialog = false"
                    >
                      Cancelar
                    </v-btn>
                    <Confirmar @action="save"/>
                  </div>
                  <v-btn
                    v-else
                    color="accent"
                    @click="dialog = false"
                  >Volver</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in formacion_academica"
          :key="index"
        >
          <td v-if="!$vuetify.breakpoint.xs">{{ item.inicio }} - {{ isCurso(item.fin) }}</td>
          <td>{{ item.nivel }}</td>
          <td>{{ item.titulo }}</td>
          <td>
            <div class="d-flex justify-end">
              <v-btn icon color="info" title="Ver" @click="edit(index)"><v-icon small>fas fa-eye</v-icon></v-btn>
              <DeleteTableItem v-if="!view" @action="delet(index)" />
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { db } from '../../firebase'
import Confirmar from '../utils/Confirmar'
import DeleteTableItem from '../utils/DeleteTableItem'

export default {
  data() {
    return {
      dialog: false,
      encurso: false,
      alert: {
        value: false,
        type: 'error',
        message: '',
      },
      tipos_formacion_academica: [],
      rules: { required: value => !!value || 'Campo requerido' },
      editedIndex: -1,
      formacion: {
        nivel: '',
        titulo: '',
        institucion: '',
        inicio: new Date().getFullYear(),
        fin: new Date().getFullYear(),
        observaciones: '',
      }
    }
  },
  async created() {
    await db.collection('tipos_formacion_academica').get()
      .then((query) => {
        query.forEach((doc) => {
          this.tipos_formacion_academica.push(doc.data().nombre)
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  props: {
    form_academica: Array,
    view: {
      type: Boolean,
      default: false,
    },
    postulante_id: {
      type: String,
      default: '',
    }
  },
  components: {
    Confirmar, DeleteTableItem
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  computed: {
    ...mapState(['user']),
    formacion_academica: {
      get () {
        return this.form_academica.sort((a, b) => b.inicio - a.inicio)
      },
      set (value) {
        this.$emit('update:form_academica', value)
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva' : 'Editar'
    },
  },
  methods: {
    ...mapActions(['update_user']),
    async actualizar_db(payload) {
      const temp_formacion_academica = [...this.formacion_academica]

      this.update({ 'array': temp_formacion_academica, 'accion': payload.accion, 'index': payload.index })
      this.$store.state.loading_screen = true

      if (this.user.rol == 'Admin') {
        // esta actualizando el admin, tiene que enviar el uid del postulante
        if (!this.postulante_id) {
          this.$store.dispatch('show_snackbar', {
            text: 'UID de postulante incorrecto',
            color: 'error',
          })
          this.$store.state.loading_screen = false
          return
        }

        await db.collection('usuarios').doc(this.postulante_id).update({ 'cv.formacion': temp_formacion_academica })
        .then(() => {
          this.update({ 'array': this.formacion_academica, 'accion': payload.accion, 'index': payload.index })
          this.$store.dispatch('show_snackbar', {
            text: 'Los cambios se guardaron correctamente',
            color: 'success',
          })
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      } else {
        // esta actualizando el postulante por ende toma al usuario del store
        let cv = Object.assign({}, this.user.cv)
        cv.formacion = [...temp_formacion_academica]

        await db.collection('usuarios').doc(this.user.id).update({ cv: cv })
          .then(() => {
            this.update({ 'array': this.formacion_academica, 'accion': payload.accion, 'index': payload.index })
            this.update_user(this.user)
            this.$store.dispatch('show_snackbar', {
              text: 'Los cambios se guardaron correctamente',
              color: 'success',
            })
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      this.$store.state.loading_screen = false
    },
    update(payload) {
      switch (payload.accion) {
        case 0:
          payload.array.push(this.formacion)
          break

        case 1:
          payload.array[this.editedIndex] = this.formacion
          break

        case 2:
          payload.array.splice(payload.index, 1)
          break
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (!this.encurso && (this.formacion.inicio > this.formacion.fin)) {
          this.alert.value = true
          this.alert.message = 'El año de inicio no puede ser mayor que el año de finalización'
          return true
        }
        if (this.editedIndex === -1) {
          await this.actualizar_db({ 'accion': 0 })
        } else {
          await this.actualizar_db({ 'accion': 1 })
        }
        this.dialog = false
      }
    },
    async edit(index) {
      this.editedIndex = index
      this.formacion = await Object.assign({}, this.formacion_academica[this.editedIndex])
      if (this.formacion.nivel.includes('en curso')) {
        this.encurso = true
      } else {
        this.encurso = false
      }
      this.dialog = true
    },
    async delet(index) {
      await this.actualizar_db({ 'accion': 2, 'index': index })
    },
    curso() {
      if (this.formacion.nivel.includes('en curso')) {
        this.encurso = true
        this.formacion.fin = null
      } else {
        this.encurso = false
        this.formacion.fin = new Date().getFullYear()
      }
    },
    close() {
      this.alert.value = false
      this.encurso = false
      this.editedIndex = -1
      this.formacion = {
        nivel: '',
        titulo: '',
        institucion: '',
        inicio: new Date().getFullYear(),
        fin: new Date().getFullYear(),
        observaciones: '',
      }
      this.$refs.form.resetValidation()
    },
    isCurso (date) {
      if (!date) return 'En curso'
      return date
    }
  }
}
</script>