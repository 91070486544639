<template>
  <v-dialog v-model="dialog" max-width="350px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        title="Eliminar"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>fas fa-trash</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="font-weight-light" style="word-break: normal">¿Desea eliminar éste {{ nombre }}?</v-card-title>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn color="success" class="mr-2" @click="confirmar">Sí</v-btn>
        <v-btn color="error" @click="dialog = false">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  props: {
    nombre: {
      type: String,
      default: 'elemento'
    }
  },
  methods: {
    confirmar() {
      this.$emit('action')
      this.dialog = false
    }
  }
}
</script>