<template>
    <v-simple-table fixed-header height="200px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left"><h3>Nombre</h3></th>
          <th v-if="!$vuetify.breakpoint.xs" class="text-left"><h3>Tipo</h3></th>
          <th class="text-right">
            <v-dialog
              v-model="dialog"
              width="500"
              :persistent="load"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  title="Subir"
                  :disabled="view"
                  icon
                  v-bind="attrs"
                  v-on="on"
                ><v-icon>fas fa-upload</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-text class="pt-6 px-4">
                  <v-row
                    no-gutters
                    class="d-flex justify-center mx-4"
                    style="background-color: #f0f0f0; border-style: dashed; border-width: 2px; border-radius: 15px; cursor: pointer"
                    @dragover.prevent="icon_large = true"
                    @dragleave.prevent="icon_large = false"
                    @drop.prevent="drop"
                    @click="$refs.input.click()"
                  >
                    <input type="file" ref="input" class="d-none" accept=".pdf,.doc,.odt,.docx,.mp4,.avi,.mpg,.webm" @change="buscar($event)">
                    <v-col class="d-flex justify-center mt-6" cols="12">
                      <v-icon :large="icon_large" :color="icon_color">{{ icon }}</v-icon>
                    </v-col>
                    <v-col class="d-flex justify-center mt-2 mb-4" cols="12">
                      <h1 class="body-1 font-weight-bold mx-4 text-center">{{ text }}</h1>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end pb-4">
                  <v-row no-gutters>
                    <v-col class="d-flex justify-end align-center mb-md-0 mb-2">
                      <h1 class="body-1 error--text mr-4 mt-n2">{{ message }}</h1>
                      <h1 v-if="load" class="body-1 mr-4">Subiendo archivo...</h1>
                    </v-col>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        color="error"
                        :disabled="load"
                        @click="dialog = false"
                      >Cancelar</v-btn>
                      <v-btn
                        v-if="file"
                        class="mx-4"
                        color="success"
                        :loading="load"
                        @click="verificar_existencia"
                      >Subir<v-icon right>fas fa-file-upload</v-icon></v-btn>
                      <v-btn
                        v-else
                        class="mx-4"
                        color="primary"
                        @click="$refs.input.click()"
                      >Buscar<v-icon right>fas fa-folder-open</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>

              <v-dialog v-model="dialog_duplicado" max-width="410px">
                <v-card>
                  <v-card-title class="font-weight-light" style="word-break: normal">Usted ya subió un archivo con el mismo nombre ¿Desea reemplazarlo?</v-card-title>
                  <v-card-actions class="d-flex justify-end pb-4">
                    <v-btn color="success" class="mr-2" @click="subir(true)">Sí</v-btn>
                    <v-btn color="error" @click="dialog_duplicado = false">No</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </v-dialog>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in usuario.archivos"
          :key="index"
        >
          <td>{{ item.nombre }}</td>
          <td v-if="!$vuetify.breakpoint.xs">
            <v-icon v-if="item.tipo === 'PDF'" left small color="red darken-4">fas fa-file-pdf</v-icon>
            <v-icon v-else-if="item.tipo === 'Documento'" left small color="blue darken-4">fas fa-file-word</v-icon>
            <v-icon v-else left small color="amber darken-1">fas fa-file-video</v-icon>
            {{ item.tipo }}
          </td>
          <td>
            <div class="d-flex justify-end">
              <v-btn v-if="item.tipo === 'Video'" icon title="Ver" :href="item.link" target="_blank" @click="video_visto(item.tipo)"><v-icon small>fas fa-external-link-alt</v-icon></v-btn>
              <v-btn v-else icon title="Descargar" :href="item.link" target="_blank"><v-icon small>fas fa-download</v-icon></v-btn>
              <DeleteTableItem v-if="!view" nombre="archivo" @action="eliminar({ nombre: item.nombre, index: index})" />
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { db, storage } from '../firebase'
import { mapActions, mapState } from 'vuex'
import DeleteTableItem from '../components/utils/DeleteTableItem'

export default {
  data() {
    return {
      file: null,
      dialog: false,
      dialog_duplicado: false,
      load: false,
      icon_large: false,
      icon_color: '',
      icon: 'fas fa-file-upload',
      text: 'Arrastre el archivo o haga click aquí',
      tipo: '',
      message: '',
    }
  },
  props: {
    usuario: Object,
    conv: String,
    view: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    DeleteTableItem
  },
  watch: {
    file(val) {
      if (this.file) {
        this.text = val.name
      }
    },
    dialog (val) {
      val || this.clear()
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions(['update_user']),
    async video_visto (tipo) {
      if (this.conv && tipo == 'Video') {
        this.$store.state.loading_screen = true
        try {
          await db.collection('convocatorias').doc(this.conv).collection('postulantes').doc(this.usuario.id).update({ video_visto: true })
            .then(() => {
              // todo ok
            })
            .catch((error) => {
              console.log(error)
              this.$store.dispatch('show_snackbar', {
                text: 'Ocurrio un error al marcar el video como visto: ' + error.message,
                color: 'error',
              })
            }) 
        } catch (error) {
          console.log(error)
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un error general al marcar el video como visto: ' + error.message,
            color: 'error',
          })
        }
        this.$store.state.loading_screen = false
      }
    },
    drop(event) {
      if (event.dataTransfer.files.length > 1) {
        this.clear()
        this.message = 'Solo puede subir 1 archivo a la vez'
        return
      }
      this.validar(event.dataTransfer.files[0])
    },
    buscar(event) {
      const archivo = event.target.files[0]
      if (archivo !== undefined) this.validar(archivo)
    },
    validar(archivo) {
      const tipo = typeof archivo.type == 'string' ? archivo.type.toLowerCase() : archivo.type
      this.icon_large = true
      this.message = ''
      if (tipo === 'application/pdf' || tipo === 'application/msword' ||  tipo === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || tipo === 'application/vnd.oasis.opendocument.text') {
        this.file = archivo
        if (tipo === 'application/pdf') {
          this.tipo = 'PDF'
          this.icon = 'fas fa-file-pdf'
          this.icon_color = 'red darken-4'
        } else {
          this.tipo = 'Documento'
          this.icon = 'fas fa-file-word'
          this.icon_color = 'blue darken-4'
        }
      } else if (tipo === '') {
        const nombre = archivo.name
        if (nombre.substring(nombre.length - 5, nombre.length) === '.docx' || nombre.substring(nombre.length - 4, nombre.length) === '.doc') {
          this.file = archivo
          this.tipo = 'Documento'
          this.icon = 'fas fa-file-word'
          this.icon_color = 'blue darken-4'
        } else {
          this.invalido(tipo)
        }
      } else if (tipo === 'video/mp4' || tipo === 'video/x-msvideo' || tipo === 'video/mpeg' || tipo === 'video/webm' ||
                 tipo === 'video/ogg' || tipo === 'video/mp2t' || tipo === 'video/3gpp' || tipo === 'video/3gpp2' ||
                 tipo === 'video/avi' || tipo === 'video/quicktime' || tipo === 'video/x-m4v' || tipo === 'video/x-flv' || tipo === 'video/x-ms-wmv') {
        if (this.usuario.archivos.find(arch => arch.tipo === 'Video')) {
          this.clear()
          this.message = 'Solo se puede subir un video'
          return
        }
        if (archivo.size > 1024 * 1024 * 80) {
          this.clear()
          this.message = 'El tamaño del video debe ser menor a 80MB'
          return
        }
        this.file = archivo
        this.tipo = 'Video'
        this.icon = 'fas fa-file-video'
        this.icon_color = 'amber darken-1'
      } else {
        this.invalido(tipo)
      }
    },
    verificar_existencia() {
      if (this.usuario.archivos.filter(file => file.nombre.indexOf(this.file.name) === 0).length === 0) {
        this.subir(false)
      } else {
        this.dialog_duplicado = true
      }
    },
    async subir(existe) {
      this.dialog_duplicado = false
      this.load = true

      const doc = storage.ref().child(this.usuario.id).child(this.file.name)
      await doc.put(this.file)
        .then(async () => {

          await doc.getDownloadURL()
            .then(async (url) => {

              let archivos = [...this.usuario.archivos]
              let archivo = {}

              if (existe) {
                archivos.filter(file => file.nombre.indexOf(this.file.name) === 0)[0].link = url
              } else {
                archivo = {
                  nombre: this.file.name,
                  tipo: this.tipo,
                  link: url
                }
                archivos.push(archivo)
              }

              await db.collection('usuarios').doc(this.usuario.id).update({ archivos: archivos })
                .then(() => {
                  this.usuario.archivos = [...archivos]
                  if (this.user.rol != 'Admin') {
                    this.update_user(this.usuario)
                  }
                  this.$store.dispatch('show_snackbar', {
                    text: 'Archivo subido correctamente',
                    color: 'success',
                  })
                })
                .catch((error) => {
                  console.error(error)
                })

            })
            .catch((error) => {
              console.error(error)
            })

        })
        .catch((error) => {
          console.error(error)
        })

      this.load = false
      this.dialog = false
    },
    async eliminar(payload) {
      this.$store.state.loading_screen = true

      let archivos = [...this.usuario.archivos]
      archivos.splice(payload.index, 1)

      await db.collection('usuarios').doc(this.usuario.id).update({ archivos: archivos })
        .then(async () => {
          await storage.ref().child(this.usuario.id).child(payload.nombre).delete()
            .then(() => {
              this.usuario.archivos = [...archivos]
              if (this.user.rol != 'Admin') {
                this.update_user(this.usuario)
              }
              this.$store.dispatch('show_snackbar', {
                text: 'Archivo eliminado correctamente',
                color: 'success',
              })
            })
            .catch((error) => {
              console.error(error)
            })
        })
        .catch((error) => {
          console.error(error)
        })

      this.$store.state.loading_screen = false
    },
    invalido(tipo) {
      this.clear()
      this.message = 'Tipo de archivo no válido' + ` (${tipo})`
    },
    clear() {
      this.$refs.input.value = ''
      this.file = null
      this.icon_large = false
      this.icon_color = ''
      this.icon = 'fas fa-file-upload'
      this.text = 'Arrastre el archivo o haga click aquí'
      this.tipo = ''
      this.message = ''
    },
  }
}
</script>