<template>
  <v-simple-table fixed-header height="200px" :style="readonly">
    <template v-slot:default>
      <thead>
        <tr>
          <th v-if="!$vuetify.breakpoint.xs" class="text-left" :style="readonly"><h3>Periodo</h3></th>
          <th class="text-left" :style="readonly"><h3>Puesto</h3></th>
          <th class="text-left" :style="readonly"><h3>Organización</h3></th>
          <th class="text-right" :style="readonly">
            <v-dialog
              v-model="dialog"
              width="400"
              scrollable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="disabled || view"
                  color="success"
                  title="Agregar"
                  icon
                  v-bind="attrs"
                  v-on="on"
                ><v-icon>fas fa-plus</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-title v-if="!view" class="font-weight-light">{{ formTitle }} experiencia laboral</v-card-title>
                <v-card-title v-else class="font-weight-light">Detalle</v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="experiencia.puesto"
                          :rules="[rules.required]"
                          label="Puesto"
                          validate-on-blur
                          :readonly="view"
                          :filled="view"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="experiencia.empresa"
                          :rules="[rules.required]"
                          label="Organización"
                          validate-on-blur
                          :readonly="view"
                          :filled="view"
                        ></v-text-field>
                      </v-col>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model.trim="experiencia.fecha.inicio"
                            :rules="[rules.required, rules.date]"
                            :clearable="!view"
                            :readonly="view"
                            :filled="view"
                            label="Fecha inicio"
                            prepend-icon="fas fa-calendar"
                            placeholder="dd/mm/aaaa"
                            v-mask="'##/##/####'"
                            validate-on-blur
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-if="encurso"
                            v-model.trim="experiencia.fecha.fin"
                            label="En curso"
                            disabled
                            prepend-icon="fas fa-hourglass-half"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            v-model.trim="experiencia.fecha.fin"
                            :rules="[rules.required, rules.date]"
                            :clearable="!view"
                            :readonly="view"
                            :filled="view"
                            label="Fecha finalización"
                            prepend-icon="fas fa-calendar"
                            placeholder="dd/mm/aaaa"
                            v-mask="'##/##/####'"
                            validate-on-blur
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-col v-if="!view" cols="12" class="d-flex">
                        <v-checkbox
                          v-model="encurso"
                          label="Actualmente en curso"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model.trim="experiencia.tareas_realizadas"
                          :rules="[rules.required]"
                          label="Tareas realizadas"
                          auto-grow
                          rows="2"
                          validate-on-blur
                          :readonly="view"
                          :filled="view"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-alert
                    v-if="alert.value"
                    border="left"
                    text
                    dense
                    :type="alert.type"
                  >
                    {{ alert.message }}
                  </v-alert>
                </v-card-text>
                <v-card-actions class="d-flex justify-end pb-4">
                  <div v-if="!view">
                    <v-btn
                      color="error"
                      class="mr-4"
                      @click="dialog = false"
                    >
                      Cancelar
                    </v-btn>
                    <Confirmar @action="save"/>
                  </div>
                  <v-btn
                    v-else
                    color="accent"
                    @click="dialog = false"
                  >Volver</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in experiencias"
          :key="index"
        >
          <td v-if="!$vuetify.breakpoint.xs">{{ getYear(item.fecha.inicio) }} - {{ getYear(item.fecha.fin) }}</td>
          <td>{{ item.puesto }}</td>
          <td>{{ item.empresa }}</td>
          <td>
            <div class="d-flex justify-end">
              <v-btn icon color="info" title="Ver" @click="edit(index)"><v-icon small>fas fa-eye</v-icon></v-btn>
              <DeleteTableItem v-if="!view" @action="delet(index)" />
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { db } from '../../firebase'
import Confirmar from '../utils/Confirmar'
import DeleteTableItem from '../utils/DeleteTableItem'
import moment from 'moment'

export default {
  data() {
    return {
      dialog: false,
      encurso: false,
      alert: {
        value: false,
        type: 'error',
        message: '',
      },
      rules: {
        required: value => !!value || 'Campo requerido',
        date: value => moment(value, 'DD/MM/YYYY', true).isValid() || 'Fecha inválida'
      },
      editedIndex: -1,
      experiencia: {
        fecha: { inicio: '', fin: '', },
        empresa: '',
        puesto: '',
        tareas_realizadas: '',
      }
    }
  },
  props: {
    xp_laboral: Array,
    disabled: Boolean,
    view: {
      type: Boolean,
      default: false,
    },
    postulante_id: {
      type: String,
      default: '',
    }
  },
  components: {
    Confirmar, DeleteTableItem
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    encurso (val) {
      if (val) this.experiencia.fecha.fin = ''
    }
  },
  computed: {
    ...mapState(['user']),
    readonly(){
      return [ this.disabled ? { 'background-color' : '#f0f0f0' } : {} ]
    },
    experiencias: {
      get () {
        return this.xp_laboral.sort((a, b) => this.getYear(b.fecha.inicio) - this.getYear(a.fecha.inicio))
      },
      set (value) {
        this.$emit('update:xp_laboral', value)
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva' : 'Editar'
    },
  },
  methods: {
    ...mapActions(['update_user']),
    async actualizar_db(payload) {
      const temp_experiencias = [...this.experiencias]

      await this.update({ 'array': temp_experiencias, 'accion': payload.accion, 'index': payload.index })
      this.$store.state.loading_screen = true

      if (this.user.rol == 'Admin') {
        // esta actualizando el admin, tiene que enviar el uid del postulante
        if (!this.postulante_id) {
          this.$store.dispatch('show_snackbar', {
            text: 'UID de postulante incorrecto',
            color: 'error',
          })
          this.$store.state.loading_screen = false
          return
        }

        await db.collection('usuarios').doc(this.postulante_id).update({ 'cv.experiencia': temp_experiencias })
        .then(async () => {
          await this.update({ 'array': this.experiencias, 'accion': payload.accion, 'index': payload.index })
          this.$store.dispatch('show_snackbar', {
            text: 'Los cambios se guardaron correctamente',
            color: 'success',
          })
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      } else {
        // esta actualizando el postulante por ende toma al usuario del store
        let cv = Object.assign({}, this.user.cv)
        cv.experiencia = [...temp_experiencias]

        await db.collection('usuarios').doc(this.user.id).update({ cv: cv })
          .then(async () => {
            await this.update({ 'array': this.experiencias, 'accion': payload.accion, 'index': payload.index })
            this.update_user(this.user)
            this.$store.dispatch('show_snackbar', {
              text: 'Los cambios se guardaron correctamente',
              color: 'success',
            })
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      this.$store.state.loading_screen = false
    },
    async update(payload) {
      const xp = {
        fecha: {
          inicio: await this.parseDate(this.experiencia.fecha.inicio),
          fin: await this.parseDate(this.experiencia.fecha.fin),
        },
        empresa: this.experiencia.empresa,
        puesto: this.experiencia.puesto,
        tareas_realizadas: this.experiencia.tareas_realizadas,
      }
      switch (payload.accion) {
        case 0:
          payload.array.push(xp)
          break
        case 1:
          payload.array[this.editedIndex] = xp
          break

        case 2:
          payload.array.splice(payload.index, 1)
          break
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        const fecha_inicio = await this.parseDate(this.experiencia.fecha.inicio)
        const fecha_fin = await this.parseDate(this.experiencia.fecha.fin)

        if (!this.encurso && (fecha_inicio > fecha_fin)) {
          this.alert.value = true
          this.alert.message = 'La fecha de inicio no puede ser mayor que la fecha de finalización'
          return true
        }
        if (this.editedIndex === -1) {
          await this.actualizar_db({ 'accion': 0 })
        } else {
          await this.actualizar_db({ 'accion': 1 })
        }
        this.dialog = false
      }
    },
    async edit(index) {
      this.editedIndex = index
      this.experiencia = Object.assign({}, {
        fecha: {
          inicio: await this.formatDate(this.experiencias[this.editedIndex].fecha.inicio),
          fin: await this.formatDate(this.experiencias[this.editedIndex].fecha.fin),
        },
        empresa: this.experiencias[this.editedIndex].empresa,
        puesto: this.experiencias[this.editedIndex].puesto,
        tareas_realizadas: this.experiencias[this.editedIndex].tareas_realizadas,
      })
      if (index !== -1 && this.experiencia.fecha.fin === null) this.encurso = true
      this.dialog = true
    },
    async delet(index) {
      await this.actualizar_db({ 'accion': 2, 'index': index })
    },
    async close() {
      this.alert.value = false
      this.encurso = false
      this.editedIndex = -1
      this.experiencia = {
        fecha: {
          inicio: await this.formatDate(''),
          fin: await this.formatDate(''),
        },
        empresa: '',
        puesto: '',
        tareas_realizadas: '',
      }
      this.$refs.form.resetValidation()
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getYear (date) {
    if (!date) return 'En curso'
      const [year] = date.split('-')
      return `${year}`
    }
  }
}
</script>