<template>
  <v-simple-table fixed-header height="240px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left"><h3>Descripción</h3></th>
          <th class="text-left"><h3>Nivel</h3></th>
          <th v-if="!view" class="text-right">
            <v-dialog
              v-model="dialog"
              width="400"
              scrollable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  title="Agregar"
                  icon
                  v-bind="attrs"
                  v-on="on"
                ><v-icon>fas fa-plus</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-title class="font-weight-light">{{ formTitle }} conocimiento</v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model="conocimiento.descripcion"
                          :rules="[rules.required]"
                          label="Descripción"
                          validate-on-blur
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="conocimiento.nivel"
                          :items="niveles"
                          label="Nivel"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-alert
                    v-if="alert.value"
                    border="left"
                    text
                    dense
                    :type="alert.type"
                  >
                    {{ alert.message }}
                  </v-alert>
                </v-card-text>
                <v-card-actions class="d-flex justify-end pb-4">
                  <v-btn
                    color="error"
                    class="mr-4"
                    @click="dialog = false"
                  >
                    Cancelar
                  </v-btn>
                  <Confirmar @action="save"/>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in conocimientos"
          :key="index"
        >
          <td>{{ item.descripcion }}</td>
          <td>{{ item.nivel }}</td>
          <td  v-if="!view">
            <div class="d-flex justify-end">
              <v-btn icon color="primary" title="Editar" @click="edit(index)"><v-icon small>fas fa-pen</v-icon></v-btn>
              <DeleteTableItem @action="delet(index)" />
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { db } from '../../firebase'
import Confirmar from '../utils/Confirmar'
import DeleteTableItem from '../utils/DeleteTableItem'

export default {
  data() {
    return {
      dialog: false,
      alert: {
        value: false,
        type: 'error',
        message: '',
      },
      niveles: ['Básico', 'Medio', 'Avanzado'],
      rules: { required: value => !!value || 'Campo requerido' },
      editedIndex: -1,
      conocimiento: {
        descripcion: '',
        nivel: 'Básico',
      }
    }
  },
  props: {
    otros_conocimientos: Array,
    view: {
      type: Boolean,
      default: false,
    },
    postulante_id: {
      type: String,
      default: '',
    }
  },
  components: {
    Confirmar, DeleteTableItem
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  computed: {
    ...mapState(['user']),
    conocimientos: {
      get () {
        return this.otros_conocimientos
      },
      set (value) {
        this.$emit('update:otros_conocimientos', value)
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo' : 'Editar'
    },
  },
  methods: {
    ...mapActions(['update_user']),
    async actualizar_db(payload) {
      const temp_conocimientos = [...this.conocimientos]

      this.update({ 'array': temp_conocimientos, 'accion': payload.accion, 'index': payload.index })
      this.$store.state.loading_screen = true

      if (this.user.rol == 'Admin') {
        // esta actualizando el admin, tiene que enviar el uid del postulante
        if (!this.postulante_id) {
          this.$store.dispatch('show_snackbar', {
            text: 'UID de postulante incorrecto',
            color: 'error',
          })
          this.$store.state.loading_screen = false
          return
        }

        await db.collection('usuarios').doc(this.postulante_id).update({ 'cv.conocimientos': temp_conocimientos })
        .then(() => {
          this.update({ 'array': this.conocimientos, 'accion': payload.accion, 'index': payload.index })
          this.$store.dispatch('show_snackbar', {
            text: 'Los cambios se guardaron correctamente',
            color: 'success',
          })
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      } else {
        // esta actualizando el postulante por ende toma al usuario del store
        let cv = Object.assign({}, this.user.cv)
        cv.conocimientos = [...temp_conocimientos]

        await db.collection('usuarios').doc(this.user.id).update({ cv: cv })
          .then(() => {
            this.update({ 'array': this.conocimientos, 'accion': payload.accion, 'index': payload.index })
            this.update_user(this.user)
            this.$store.dispatch('show_snackbar', {
              text: 'Los cambios se guardaron correctamente',
              color: 'success',
            })
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }
      this.$store.state.loading_screen = false
    },
    update(payload) {
      switch (payload.accion) {
        case 0:
          payload.array.push(this.conocimiento)
          break

        case 1:
          payload.array[this.editedIndex] = this.conocimiento
          break

        case 2:
          payload.array.splice(payload.index, 1)
          break
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex === -1) {
          await this.actualizar_db({ 'accion': 0 })
        } else {
          await this.actualizar_db({ 'accion': 1 })
        }
        this.dialog = false
      }
    },
    edit(index) {
      this.editedIndex = index
      this.conocimiento = Object.assign({}, this.conocimientos[this.editedIndex])
      this.dialog = true
    },
    async delet(index) {
      await this.actualizar_db({ 'accion': 2, 'index': index })
    },
    close() {
      this.alert.value = false
      this.editedIndex = -1
      this.conocimiento =  {
        descripcion: '',
        nivel: 'Básico',
      }
      this.$refs.form.resetValidation()
    },
  }
}
</script>