<template>
  <v-row>
    <v-col v-if="!card" cols="12" class="d-flex justify-center">
      <h1 class="text-h5 font-weight-light">Foto de perfil</h1>
    </v-col>
    <v-col cols="12" :class="card ? 'd-flex justify-center pt-4' : 'd-flex justify-center pt-0'">
      <v-avatar
        color="secondary"
        size="180"
      >
        <v-icon v-if="usuario.foto === ''" x-large>fas fa-user</v-icon>
        <img v-else :src="usuario.foto">
      </v-avatar>
    </v-col>
    <v-col v-if="!view" cols="6" :class="card ? 'd-flex justify-end pr-2' : 'd-flex justify-end pr-1 pt-0'">
      <v-dialog
        v-model="dialog"
        width="450"
        :persistent="load"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            :small="!card"
            :block="card"
            v-bind="attrs"
            v-on="on"
          >Cambiar<v-icon right :small="!card">fas fa-camera</v-icon></v-btn>
        </template>
        <v-card>
          <v-card-text class="pt-6 px-4">
            <v-row
              no-gutters
              class="d-flex justify-center mx-4"
              style="background-color: #f0f0f0; border-style: dashed; border-width: 2px; border-radius: 15px; cursor: pointer"
              @dragover.prevent="icon_large = true"
              @dragleave.prevent="icon_large = false"
              @drop.prevent="drop"
              @click="$refs.input.click()"
            >
              <input type="file" ref="input" class="d-none" accept=".jpg,.png" @change="buscar($event)">
              <v-col class="d-flex justify-center mt-6" cols="12">
                <v-icon v-if="urlT === ''" :large="icon_large">fas fa-file-image</v-icon>
                <v-img v-else :src="urlT" max-width="30%"></v-img>
              </v-col>
              <v-col class="d-flex justify-center mt-2 mb-4" cols="12">
                <h1 class="body-1 font-weight-bold mx-4 text-center">{{ text }}</h1>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pb-4">
            <v-row no-gutters>
              <v-col class="d-flex justify-end align-center mb-md-0 mb-2">
                <h1 class="body-1 error--text mr-4 mt-n2">{{ message }}</h1>
                <h1 v-if="load" class="body-1 mr-4">Subiendo imagen...</h1>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn
                  color="error"
                  :disabled="load"
                  @click="dialog = false"
                >Cancelar</v-btn>
                <v-btn
                  v-if="image"
                  class="mx-4"
                  color="success"
                  :loading="load"
                  @click="subir"
                >Subir<v-icon right>fas fa-file-upload</v-icon></v-btn>
                <v-btn
                  v-else
                  class="mx-4"
                  color="primary"
                  @click="$refs.input.click()"
                >Buscar<v-icon right>fas fa-folder-open</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col v-if="!view" cols="6" :class="card ? 'd-flex justify-start pl-2' : 'd-flex justify-start pl-1 pt-0'">
      <v-dialog
        v-model="dialog_eliminar"
        max-width="350px"
        :persistent="load"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="error"
            :small="!card"
            :block="card"
            :disabled="usuario.foto === ''"
            v-bind="attrs"
            v-on="on"
          >Eliminar<v-icon right :small="!card">fas fa-trash</v-icon></v-btn>
        </template>
        <v-card>
          <v-card-title class="font-weight-light" style="word-break: normal">¿Desea eliminar su foto de perfil?</v-card-title>
          <v-card-actions class="d-flex justify-end pb-4">
            <v-btn color="success" class="mr-2" :loading="load" @click="eliminar">Sí</v-btn>
            <v-btn color="error" :disabled="load" @click="dialog_eliminar = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { db, storage } from '../firebase'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      urlT: '',
      image: null,
      dialog: false,
      dialog_eliminar: false,
      load: false,
      icon_large: false,
      text: 'Arrastre la imagen o haga click aquí',
      message: '',
    }
  },
  async created() {

  },
  props: {
    usuario: Object,
    card: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    image(val) {
      if (this.image) {
        this.text = val.name
      }
    },
    dialog (val) {
      val || this.clear()
    },
  },
  methods: {
    ...mapActions(['update_user']),
    drop(event) {
      if (event.dataTransfer.files.length > 1) {
        this.clear()
        this.message = 'Solo puede subir una imagen'
        return
      }
      this.validar(event.dataTransfer.files[0])
    },
    buscar(event) {
      const imagen = event.target.files[0]
      if (imagen !== undefined) this.validar(imagen)
    },
    validar(imagen) {
      const tipo = imagen.type
      this.icon_large = true
      this.message = ''
      if (tipo === 'image/png' || tipo === 'image/jpeg') {
        if (imagen.size > 1024 * 1024 * 3) {
          this.clear()
          this.message = 'La imagen no debe pesar más de 3MB'
          return
        }
        this.image = imagen
        const reader = new FileReader()
        reader.readAsDataURL(this.image)
        reader.onload = (e) => { this.urlT = e.target.result }
      } else {
        this.invalido()
      }
    },
    async subir() {
      this.load = true
      const doc = storage.ref().child(this.usuario.id).child('imagen_perfil')
      await doc.put(this.image)
        .then(async () => {
          await doc.getDownloadURL()
            .then(async (url) => {
              await db.collection('usuarios').doc(this.usuario.id).update({ foto: url })
                .then(() => {
                  this.usuario.foto = url
                  if (this.user.rol != 'Admin') {
                    this.update_user(this.usuario)
                  }
                })
                .catch((error) => {
                  this.$store.dispatch('show_snackbar', {
                    text: error.message,
                    color: 'error'
                  })
                })
            })
            .catch((error) => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
      this.dialog = false
    },
    async eliminar () {
      this.load = true
      await db.collection('usuarios').doc(this.usuario.id).update({ foto: '' })
        .then(async () => {
          await storage.ref().child(this.usuario.id).child('imagen_perfil').delete()
            .then(() => {
              this.usuario.foto = ''
              if (this.user.rol != 'Admin') {
                this.update_user(this.usuario)
              }
            })
            .catch((error) => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
      this.dialog_eliminar = false
    },
    invalido() {
      this.clear()
      this.message = 'Tipo de archivo no válido'
    },
    clear() {
      this.$refs.input.value = ''
      this.urlT = ''
      this.image = null
      this.icon_large = false
      this.text = 'Arrastre la imagen o haga click aquí'
      this.message = ''
    },
  }
}
</script>